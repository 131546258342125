<template>
	<div class="staff">
		<div class="opList">
			<div>
				<a-select placeholder="部门" class="select" @change="handleChange" allowClear>
					<a-select-option :value="item.id" v-for="item in department" :key="item.id">
						{{item.name}}
					</a-select-option>
				</a-select>
				<a-input-search class="search" placeholder="输入工号/姓名关键词搜索" enter-button="搜索" @search="onSearch" allowClear/>
			</div>
			<a-button type="primary" icon="plus" @click="showDrawer">新增员工</a-button>
		</div>
		<a-table :columns="columns" :row-key="record => record.id" :data-source="data" :loading="loading" :pagination="pagination" @change="handleTableChange">
			<span slot="action" slot-scope="text">
				<a @click="editStaff(text)">编辑</a>
				<a-divider type="vertical" />
				<a-popconfirm
					title="确定删除此员工?"
					ok-text="确定"
					cancel-text="取消"
					@confirm="deleteStaff(text)"
				>
					<a href="#">删除</a>
				</a-popconfirm>
				
			</span>
		</a-table>
    <a-drawer
      :title="editUser ? '编辑员工' : '新增员工'"
      :width="720"
      :visible="visible"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    >
      <a-form :form="form" layout="vertical" hide-required-mark>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="部门">
              <a-select
                v-decorator="[
                  'department',
                  {
                    rules: [{ required: true, message: '请选择部门' }],
                  },
                ]"
                placeholder="请选择部门"
								@change="handleChangeEdit"
              >
                <a-select-option :value="item.id" v-for="item in department" :key="item.id">
                  {{item.name}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="工号">
              <a-input
                v-decorator="[
                  'jobNumber',
                  {
                    rules: [{ required: true, pattern: new RegExp(/^[0-9]\d*$/g), message: '请输入工号' }],
                  },
                ]"
                style="width: 100%"
                :addon-before="currentDepartment.prefix"
                placeholder="请输入工号"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">

          <a-col :span="12">
            <a-form-item label="姓名">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [{ required: true, message: '请输入姓名' }],
                  },
                ]"
                placeholder="请输入姓名"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="密码">
              <a-input
                v-decorator="[
                  'password',
                  {
                    rules: [{ required: true, message: '请输入密码' }],
                  },
                ]"
                placeholder="请输入密码"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="性别">
              <a-select
                v-decorator="[
                  'sex',
                  {
                    rules: [{ required: true, message: '请选择性别' }],
                  },
                ]"
                placeholder="请选择性别"
              >
                <a-select-option value="0">
                  男
                </a-select-option>
                <a-select-option value="1">
                  女
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="onClose">
          取消
        </a-button>
        <a-button type="primary" @click="handleSubmit">
          提交
        </a-button>
      </div>
    </a-drawer>
	</div>
</template>

<script>
const key = 'updatable';
import { columns } from './columns.config'
import { AddStaff_Api, SearchStaff_Api, EditStaff_Api, DelStaff_Api } from '@/service/api'
export default {
	props: {
		department: {
			type: Array
		}
	},
  data() {
    return {
			pagination: {},
      data: [],
      columns,
			// department: [],
			loading: false,
      form: this.$form.createForm(this),
			visible: false,
			currentDepartment: {id: 2, prefix: "KD", name: "开单部", power: "1"},
			searchDepartment: '', //搜索条件-部门id
			search: '', //搜索条件-搜索
			editUser: false,
			currentId: '',
    };
  },
	mounted(){
		this.getStaffList()
		// this.department = this.GETDEPARTMENT
		console.log(this.department)
	},
	methods:{
		// 编辑员工
		editStaff(item) {
      this.editUser = true
			this.visible = true
			const result = this.department.find(value=>{
				return value.id ===  item.department_id
			})
			this.currentDepartment = result
      this.$nextTick(()=>{
        this.form.setFieldsValue({
          department: item.department_id,
          jobNumber: item.job_number.replace(item.prefix, ''),
					name: item.name,
					password: item.password,
					sex: item.sex,
        })
      })
      this.currentId = item.id
		},
		// 删除员工
		async deleteStaff(item) {
			this.$message.loading({ content: 'Loading...', key });
			await DelStaff_Api({id: item.id})
			this.$message.success({ content: '删除成功！', key, duration: 2 });
			this.pagination.current = 1
			this.getStaffList()
		},

		handleTableChange(pagination) {
      console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
			this.pagination = pager;
			this.getStaffList(pagination.current)
		},
		async getStaffList(pageNo = 1) {
			this.loading = true;
			let params = {pageNo: pageNo, pageSize: 10, searchContent: {}}
			if (this.searchDepartment) {
				params['searchContent']['departmentId'] = this.searchDepartment
			}
			if (this.search) {
				params['searchContent']['search'] = this.search
			}
			const result = await SearchStaff_Api(params)

			this.loading = false;
			this.data = result.data.map(item=>{
				const departmentInfo = this.getDepartment(item.department_id)
				return {...item, department: `${departmentInfo.name}[${departmentInfo.prefix}]`, sexStr: item.sex == 0 ? '男' : '女', prefix: departmentInfo.prefix}
			})
			const pagination = { ...this.pagination };
			pagination.total = result.total;
			this.pagination = pagination
		},
		getDepartment(id) {
			return this.department.find(item => {
				return item.id == id
			})
		},
		async handleChange(value) {
			this.searchDepartment = value
			this.pagination.current = 1
			this.getStaffList()
		},
		handleChangeEdit(value){
			const result = this.department.find(item=>{
				return item.id === value
			})
			this.currentDepartment = result
		},
		onSearch(value) {
			this.search = value
			this.pagination.current = 1
			this.getStaffList()
		},
    showDrawer() {
			this.editUser = false;
			this.visible = true;
      this.$nextTick(()=>{
				this.currentDepartment = {id: 2, prefix: 'KD', name: '开单部'}
        this.form.setFieldsValue({
          department: 2,
          jobNumber: '',
					name: '',
					password: '',
					sex: '0',
        })
      })
    },
    onClose() {
      this.visible = false;
		},
		handleSubmit(e) {
			e.preventDefault();
			this.form.validateFields( async (err, values) => {
				if (!err) {
					this.$message.loading({ content: 'Loading...', key });
					if (this.editUser) {
						await EditStaff_Api({
							id: this.currentId,
							number: values.jobNumber,
							department_id: values.department,
							name: values.name,
							sex: values.sex,
							password: values.password
						})
						this.$message.success({ content: '修改成功！', key, duration: 2 });
						this.getStaffList(this.pagination.current)
						this.onClose()
					}else{
						await AddStaff_Api({
							number: values.jobNumber,
							department_id: values.department,
							name: values.name,
							sex: values.sex,
							password: values.password
						})
						this.$message.success({ content: '添加成功！', key, duration: 2 });
						this.pagination.current = 1
						this.getStaffList()
					}
				}
			})
		}
	}
};
</script>
<style scoped lang="scss">
.staff{
	overflow: auto;
	.opList{
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
		.select{
			width: 120px;
		}
		.search{
			margin-left: 20px;
			width: 300px;
		}
		
	}
}
</style>
