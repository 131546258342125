import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/Login.vue'
import Index from '../views/index/Index.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Login',component: Login },
  { path: '/Index', name: 'Index',component: Index },
]

const router = new VueRouter({
  routes
})

export default router
