var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"staff"},[_c('div',{staticClass:"opList"},[_c('div',[_c('a-select',{staticClass:"select",attrs:{"placeholder":"部门","allowClear":""},on:{"change":_vm.handleChange}},_vm._l((_vm.department),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1),_c('a-input-search',{staticClass:"search",attrs:{"placeholder":"输入工号/姓名关键词搜索","enter-button":"搜索","allowClear":""},on:{"search":_vm.onSearch}})],1),_c('a-button',{attrs:{"type":"primary","icon":"plus"},on:{"click":_vm.showDrawer}},[_vm._v("新增员工")])],1),_c('a-table',{attrs:{"columns":_vm.columns,"row-key":function (record) { return record.id; },"data-source":_vm.data,"loading":_vm.loading,"pagination":_vm.pagination},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"action",fn:function(text){return _c('span',{},[_c('a',{on:{"click":function($event){return _vm.editStaff(text)}}},[_vm._v("编辑")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a-popconfirm',{attrs:{"title":"确定删除此员工?","ok-text":"确定","cancel-text":"取消"},on:{"confirm":function($event){return _vm.deleteStaff(text)}}},[_c('a',{attrs:{"href":"#"}},[_vm._v("删除")])])],1)}}])}),_c('a-drawer',{attrs:{"title":_vm.editUser ? '编辑员工' : '新增员工',"width":720,"visible":_vm.visible,"body-style":{ paddingBottom: '80px' }},on:{"close":_vm.onClose}},[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical","hide-required-mark":""}},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"部门"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'department',
                  {
                    rules: [{ required: true, message: '请选择部门' }],
                  } ]),expression:"[\n                  'department',\n                  {\n                    rules: [{ required: true, message: '请选择部门' }],\n                  },\n                ]"}],attrs:{"placeholder":"请选择部门"},on:{"change":_vm.handleChangeEdit}},_vm._l((_vm.department),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"工号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'jobNumber',
                  {
                    rules: [{ required: true, pattern: new RegExp(/^[0-9]\d*$/g), message: '请输入工号' }],
                  } ]),expression:"[\n                  'jobNumber',\n                  {\n                    rules: [{ required: true, pattern: new RegExp(/^[0-9]\\d*$/g), message: '请输入工号' }],\n                  },\n                ]"}],staticStyle:{"width":"100%"},attrs:{"addon-before":_vm.currentDepartment.prefix,"placeholder":"请输入工号"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'name',
                  {
                    rules: [{ required: true, message: '请输入姓名' }],
                  } ]),expression:"[\n                  'name',\n                  {\n                    rules: [{ required: true, message: '请输入姓名' }],\n                  },\n                ]"}],attrs:{"placeholder":"请输入姓名"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'password',
                  {
                    rules: [{ required: true, message: '请输入密码' }],
                  } ]),expression:"[\n                  'password',\n                  {\n                    rules: [{ required: true, message: '请输入密码' }],\n                  },\n                ]"}],attrs:{"placeholder":"请输入密码"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"性别"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'sex',
                  {
                    rules: [{ required: true, message: '请选择性别' }],
                  } ]),expression:"[\n                  'sex',\n                  {\n                    rules: [{ required: true, message: '请选择性别' }],\n                  },\n                ]"}],attrs:{"placeholder":"请选择性别"}},[_c('a-select-option',{attrs:{"value":"0"}},[_vm._v(" 男 ")]),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v(" 女 ")])],1)],1)],1)],1)],1),_c('div',{style:({
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        })},[_c('a-button',{style:({ marginRight: '8px' }),on:{"click":_vm.onClose}},[_vm._v(" 取消 ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" 提交 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }