export default [
  {id: 1, prefix: 'ADMIN', name: '管理员'},
  {id: 2, prefix: 'KD', name: '开单部'},
  {id: 3, prefix: 'ZB', name: '制版部'},
  {id: 4, prefix: 'CJ', name: '裁剪部'},
  {id: 5, prefix: 'SY', name: '试样部'},
  {id: 6, prefix: 'XP', name: '修片部'},
  {id: 7, prefix: 'YF', name: '衣服生产部'},
  {id: 8, prefix: 'QK', name: '裙裤生产部'},
  {id: 9, prefix: 'ZH', name: '整合部'},
  {id: 10, prefix: 'HD', name: '后道部'},
  {id: 11, prefix: 'JY', name: '检验部'},
  {id: 12, prefix: 'CD', name: '出单部'},
  {id: 13, prefix: 'FX', name: '返修部'},
]