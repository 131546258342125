<template>
  <div class="login">
		<div class="box">
			<div class="left">
				<img src="../../assets/logo.jpg"/>
			</div>
			<div class="right">
				<p>管理端登录</p>
				<div class="info">
					<a-input v-model="name" placeholder="请输入管理员/订单部工号" size="large"/>
					<a-input-password v-model="pwd" class="pwd" placeholder="请输入密码" size="large"/>
					<a-button type="primary" block size="large" @click="login">登录</a-button>					
				</div>
			</div>			
		</div>
		<div class="copyright">
			© 卓陈越夏服饰有限公司 版本1.1.0 . All rights reserved.
		</div>
	</div>
</template>

<script>
import { Login_Api } from '@/service/api'
export default {
	data(){
		return {
			name: '',
			pwd: ''
		}
	},
	methods:{
		async login(){
			if (!this.name || !this.pwd) {
				return this.$message.error('请填写账号和密码');
			}
			const { token, userInfo } = await Login_Api({
				name: this.name,
				pwd: this.$md5(this.pwd),
				type: 'ht'
			})
			sessionStorage.setItem('token', token)
			sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
			this.$router.push({
				name: 'Index'
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.login{
	width: 100%;
	height: 100%;
	background-image: url(../../assets/bg.jpg);
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	.box{
		display: flex;
		align-items: center;
		background-color: #fff;
		padding: 20px;
		border-radius: 20px;
		box-shadow: 1px 3px 20px #3b6376;
		.left{
			img{
				width: 400px;
			}
		}
		.right{
			font-size: 28px;
			font-weight: bolder;
			text-align: center;
			width: 480px;
			height: 456px;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.info{
				margin: 0 auto;
				width: 80%;
				.pwd{
					margin: 20px 0;
				}
			}
		}		
	}
	.copyright{
		position: absolute;
		bottom: 20px;
		font-size: 14px;
		color:#fff
	}
}
</style>