<template>
	<div class="index">
		<a-layout class="layout">
			<a-layout-sider class="leftContent" v-model="collapsed" :trigger="null" collapsible>
				<div class="logo">
					<img src="../../assets/logo.png" />
					<p v-show="!collapsed">卓成越夏服饰</p>
				</div>
				<a-menu theme="dark" mode="inline" :default-selected-keys="[currentKey]" @select="selectItem">
					<a-menu-item key="staff" v-if="userMangerShow">
						<a-icon type="user" />
						<span>员工管理</span>
					</a-menu-item>
					<a-menu-item key="process">
						<a-icon type="profile" />
						<span>开单/订单管理</span>
					</a-menu-item>
				</a-menu>
			</a-layout-sider>
			<a-layout class="rightContent">
				<a-layout-header style="background: #fff; padding: 0; display:flex; justify-content: space-between;">
					<a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'"
						@click="collapsed = !collapsed" />
					<div style="padding-right: 24px">
						<span style="font-weight: bold; margin-right: 10px">{{myName}}</span>
						<a-button @click="loginout" type="link" size="small">
							退出登录
						</a-button>
						
					</div>
				</a-layout-header>
				<a-layout-content class="main">
					<Staff :department="department" v-if="currentKey == 'staff'"/>
					<ProcessList :department="department"  v-if="currentKey == 'process'"/>
				</a-layout-content>
			</a-layout>
		</a-layout>
	</div>

</template>
<script>
	import { mapMutations } from 'vuex'
	import { Department_Api } from '@/service/api'
	export default {
		data() {
			return {
				collapsed: false,
				currentKey: '',
				myName: '',
				userMangerShow: false,
				department: [],
			};
		},
		async created(){
			// 防止没登录就进后台
			const token = sessionStorage.getItem('token')
			if (!token){
				this.$router.push({name:'Login'})
			}
			if (this.$cF.getInfo().department_id == 1) {
				this.userMangerShow = true
				this.currentKey = sessionStorage.getItem('currentKey') || 'staff'
			}else{
				this.currentKey = sessionStorage.getItem('currentKey') || 'process'
			}
			this.department = await Department_Api()
			this.SETDEPARTMENT(this.department)
		},
		mounted(){
			this.myName = `Hi！${this.$cF.getInfo().job_number}`
		},
		methods:{
			...mapMutations(['SETDEPARTMENT']),
			loginout(){
				sessionStorage.clear()
				this.$router.push({name:'Login'}) 
			},
			selectItem(item){
				this.currentKey = item.key
				sessionStorage.setItem('currentKey', item.key)
			}
		}
	};
</script>
<style lang="scss" scoped>
	.index {
		width: 100%;
		height: 100%;
		.layout {
			width: 100%;
			height: 100%;
			.leftContent {
				.logo {
					display: flex;
					align-items: center;
					background-color: #001529;
					color: #d2947d;
					height: 64px;
					overflow: hidden;
					img {
						margin-left: 25px;
						line-height: 64px;
						width: 32px;
						height: 32px;
						object-fit: contain;
					}

					p {
						margin-left: 10px;
						margin-top: 25px;
						height: 32px;
					}
				}
			}

			.rightContent {
				.trigger {
					font-size: 18px;
					line-height: 64px;
					padding: 0 24px;
					cursor: pointer;
					transition: color 0.3s;
				}

				.trigger:hover {
					color: #1890ff;
				}

				.main {
					margin: 24px 16px;
					padding: 24px;
					background: #fff;
					overflow: auto;
				}
			}
		}

	}
</style>
