const columns = [{
		key: 'department',
		title: '部门',
		dataIndex: 'department',
		width: 280
	},
	{
		key: 'job_number',
		title: '工号',
		dataIndex: 'job_number',
		width: 280
	},
	{
		key: 'name',
		title: '姓名',
		dataIndex: 'name',
		width: 280
	},
	{
		key: 'password',
		title: '密码',
		dataIndex: 'password',
		width: 280
	},
	{
		key: 'sexStr',
		title: '性别',
		dataIndex: 'sexStr',
		width: 280
	},
  {
		key: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
  },
]

export { columns }
