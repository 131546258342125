const columns = [
	{
		title: '订单号',
		key: '订单号',
		dataIndex: 'order_id',
		width: 210,
		fixed: "left"
	},
	{
		title: '订单名称',
		key: '订单名称',
		dataIndex: 'list_name',
		width: 200
	},
	{
		title: '订单类型',
		key: '订单类型',
		dataIndex: 'orderType',
		width: 90
	},
	{
		title: '数量',
		key: '数量',
		dataIndex: 'numbers',
		width: 110
	},
	{
		title: '客户名称',
		key: '客户名称',
		dataIndex: 'customer_name',
		width: 200
	},
	{
		title: '联系人',
		key: '联系人',
		dataIndex: 'contact_name',
		width: 90
	},
	{
		title: '联系电话',
		key: '联系电话',
		dataIndex: 'phone',
		width: 120
	},
	{
		title: '联系地址',
		key: '联系地址',
		dataIndex: 'address',
		width: 380
	},
	{
		title: '创建人',
		key: '创建人',
		dataIndex: 'create_id',
		width: 95
	},
	{
		title: '更新人',
		key: '更新人',
		dataIndex: 'update_id',
		width: 95
	},
	{
		title: '创建时间',
		key: '创建时间',
		dataIndex: 'createTime',
		width: 200
	},
	{
		title: '更新时间',
		key: '更新时间',
		dataIndex: 'updateTime',
		width: 200
	},
	{
		title: '是否完成',
		key: '是否完成',
		dataIndex: 'isFinish',
		width: 100,
	},
  {
    title: '操作',
    key: '操作',
    scopedSlots: { customRender: 'action' },
		width: 220,
		fixed: 'right',
  },
]

export { columns }
