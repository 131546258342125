// api.js
import { httpGet, httpPost } from './http'

// 登录
export const Login_Api = (data) => {
  return httpPost({
    url: 'staff/login',
    data
  })
}

// 添加员工
export const AddStaff_Api = (data) => {
  return httpPost({
    url: 'staff/add',
    data
  })
}

// 修改员工
export const EditStaff_Api = (data) => {
  return httpPost({
    url: 'staff/update',
    data
  })
}

// 删除员工
export const DelStaff_Api = (data) => {
  return httpPost({
    url: 'staff/del',
    data
  })
}

// 员工列表
export const StaffList_Api = (params = {}) => httpGet({ url: 'staff/list', params })

// 查询员工
export const SearchStaff_Api = (params = {}) => httpGet({ url: 'staff/search', params })

// 查询订单
export const List_Api = (params = {}) => httpGet({ url: 'list/search', params })

// 订单流程
export const Process_Api = (params = {}) => httpGet({ url: 'process', params })

// 部门
export const Department_Api = (params = {}) => httpGet({ url: 'department', params })

// 修改订单
export const EditList_Api = (data) => {
  return httpPost({
    url: 'list/update',
    data
  })
}

// 新增订单
export const AddList_Api = (data) => {
  return httpPost({
    url: 'list/create',
    data
  })
}

// 删除订单
export const DelList_Api = (data) => {
  return httpPost({
    url: 'list/del',
    data
  })
}


