import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    departmentList: JSON.parse(sessionStorage.getItem('departmentList')) || [], //部门列表
  },
  mutations: {
    SETDEPARTMENT(state, departmentList){
      state.departmentList = departmentList
      sessionStorage.setItem('departmentList', JSON.stringify(departmentList))
    }
  },
  getters: {
		GETDEPARTMENT(state) {
			return state.departmentList || JSON.parse(sessionStorage.getItem('departmentList'))
		},
  },
  actions: {
  },
  modules: {
  }
})
