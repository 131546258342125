<template>
	<div class="staff">
		<div class="opList">
			<div class="left">
				<a-select placeholder="订单类型" class="typeSelect" @change="handleTypeChange" allowClear>
					<a-select-option value="0">
						上衣
					</a-select-option>
					<a-select-option value="1">
						裙裤
					</a-select-option>
					<a-select-option value="2">
						返修
					</a-select-option>
				</a-select>
				<a-select placeholder="是否完成" class="finishSelect" @change="handleChange" allowClear>
					<a-select-option value="0">
						未完成
					</a-select-option>
					<a-select-option value="1">
						已完成
					</a-select-option>
				</a-select>
				<a-range-picker :placeholder="['开始日期', '结束日期']" @change="onChange" />
				<a-input-search class="search" placeholder="订单号/订单名称关键词搜索" enter-button="搜索" @search="onSearch" allowClear/>
			</div>
			<a-button type="primary" icon="plus" @click="showDrawer">新增订单</a-button>
		</div>
		<a-table :columns="columns" :row-key="record => record.id" :data-source="data" :pagination="pagination" :scroll="{x: 1, y: 600}" :loading="loading" @change="handleTableChange">
			<span slot="action" slot-scope="text">
				<a @click="showQrCode(text)">二维码</a>
				<a-divider type="vertical" />
				<a @click="showProcess(text)">流程</a>
				<a-divider type="vertical" />
				<a @click="editList(text)">编辑</a>
				<a-divider type="vertical" />
				<a-popconfirm
					title="确定删除此订单和对应流程?"
					ok-text="确定"
					cancel-text="取消"
					@confirm="deleteList(text)"
				>
					<a href="#">删除</a>
				</a-popconfirm>
			</span>
		</a-table>
    <a-modal class="modal" v-model="createSuccessShow" :title="'订单号：'+orderId" cancelText="取消" okText="下载二维码" @ok="handleOk">
			<img class="orderImage" :src="qrcodeUrl"/>
    </a-modal>
    <a-modal v-model="processShow" :title="'订单号：'+ orderId" :footer="null" centered>
			<a-spin v-show="currentProcess.length === 0"/>
			<div class="processList">
				<div class="single" v-for="(item, index) in currentProcess" :key="index">
					<div class="step" :class="{'out': item.finish == 0}">
						<a-icon type="check" :style="{ fontSize: '14px', color: `${item.finish == 1} ? '#47a7ff' : '#08c'` }" v-show="item.finish == 1"/>
						<span v-show="item.finish == 0">{{index+1}}</span>
					</div>
					<div class="right">
						<p class="name" :class="{'finish': item.finish == 1}" >{{item.department}}{{item.finish == 1 ? `：${item.staff_id}` : ''}} </p>
						<p class="sub" :class="{'finish': item.finish == 1}" >{{ item.finish == 1 ? `完成时间：${$moment(item.finish_time).format('YYYY-MM-DD HH:mm:ss')}` : '等待完成' }} </p>
					</div>
				</div>
			</div>
			<!-- <a-steps v-show="currentProcess.length > 0" direction="vertical" :current="currentStep" size="small">
				<a-step v-for="step in currentProcess" :key="step.id" :title="`${step.name}`" :description="step.process ? `工号：${step.process.staff_id} 完成时间：${$moment(step.process.finish_time).format('YYYY-MM-DD HH:mm:ss')}` : ''" />
			</a-steps> -->
    </a-modal>
    <a-drawer
      :title=" editListShow ? '编辑订单' : '创建订单'"
      :width="720"
      :visible="visible"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    >
      <a-form :form="form" layout="vertical" hide-required-mark>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="订单名称">
              <a-input
                v-decorator="[
                  'orderName',
                  {
                    rules: [{ required: true, message: '请输入订单名称' }],
                  },
                ]"
                placeholder="请输入订单名称"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="订单数量">
              <a-input
                v-decorator="[
                  'numbers',
                  {
                    rules: [{ required: true,  pattern: new RegExp(/^[0-9]\d*$/g), message: '请输入订单数量' }],
                  },
                ]"
                placeholder="请输入订单数量"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="订单类型">
              <a-select
                v-decorator="[
                  'orderType',
                  {
                    rules: [{ required: true, message: '请选择订单类型' }],
                  },
                ]"
                placeholder="请选择订单类型"
								:disabled="editListShow"
              >
                <a-select-option value="0">
                  上衣
                </a-select-option>
                <a-select-option value="1">
                  裙裤
                </a-select-option>
                <a-select-option value="2">
                  返修
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="客户名称">
              <a-input
                v-decorator="[
                  'customerName',
                  {
                    rules: [{ required: true, message: '请输入客户名称' }],
                  },
                ]"
                placeholder="请输入客户名称"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="客户联系人姓名">
              <a-input
                v-decorator="[
                  'contactName',
                  {
                    rules: [{ required: true, message: '请输入客户联系人姓名' }],
                  },
                ]"
                placeholder="请输入客户联系人姓名"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="客户联系人电话">
              <a-input
                v-decorator="[
                  'phone',
                  {
                    rules: [{ required: true,  pattern: new RegExp(/^[0-9]\d*$/g), message: '请输入客户联系人电话' }],
                  },
                ]"
                placeholder="请输入客户联系人电话"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="客户联系地址">
              <a-input
                v-decorator="[
                  'address',
                  {
                    rules: [{ required: true, message: '请输入客户联系地址' }],
                  },
                ]"
                placeholder="请输入客户联系地址"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="onClose">
          取消
        </a-button>
        <a-button type="primary" @click="handleSubmit">
          提交
        </a-button>
      </div>
    </a-drawer>
	</div>
</template>

<script>
const key = 'updatable';
import { columns } from './columns.config'
import { AddList_Api, List_Api, Process_Api, EditList_Api, DelList_Api } from '@/service/api'
// import department from '@/common/department'
export default {
	props: {
		department: {
			type: Array
		}
	},
  data() {
    return {
			pagination: {},
      data: [],
			columns,
      form: this.$form.createForm(this),
			visible: false,
			editListShow: false,
			createSuccessShow: false,
			processShow: false,
			qrcodeUrl: '',
			urlPrefix: 'https://zcyxfs.com:7001/qrcode?orderId=',
			orderId: '',
			loading: false,
			currentStep: 0,
			currentProcess: [],
			type: '',
			finish: '',
			search: '',
			start: '',
			end: ''
    };
	},
	async mounted(){
		this.getList()
	},
	methods:{
		async deleteList(item) {
			this.$message.loading({ content: 'Loading...', key });
			await DelList_Api({id: item.id})
			this.$message.success({ content: '删除成功！', key, duration: 2 });
			this.pagination.current = 1
			this.getList()
		},
		// 编辑订单
		editList(item) {
			console.log(item.list_name)
      this.editListShow = true
			this.visible = true
      this.$nextTick(()=>{
        this.form.setFieldsValue({
					orderName: item.list_name,
					numbers: item.numbers,
					orderType: item.type,
					customerName: item.customer_name,
					contactName: item.contact_name,
					phone: item.phone,
					address: item.address
        })
      })
      this.currentId = item.id
			console.log('item',item)
		},
		handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
			this.pagination = pager;
			this.getList(pagination.current)
		},
		async showProcess(item){
			this.currentProcess = []
			this.orderId = item.order_id
			this.processShow = true
			const result = await Process_Api({
				orderId: item.order_id
			})
			this.currentProcess = result.process.map(v=>{
				const tag = this.department.find(item=> item.id == v.department_id)
				v.department = tag.name
				return v
			})
			console.log('result',result.process)
			// let processList = JSON.parse(JSON.stringify(this.department))
			// // 只保留一个管理员或者开单部的流程
			// result.process[0].department_id == 1 ? processList.splice(1,1) : processList.splice(0,1)
			// processList.forEach(element => {
			// 	const tag = result.process.find(item=> item.department_id == element.id)
			// 	if (tag) {
			// 		element['process'] = tag
			// 	}
			// });
			// this.currentProcess = processList
			// this.currentStep = result.process.length - 1
		},
		showQrCode(item){
			this.orderId = item.order_id
			this.qrcodeUrl = `${this.urlPrefix}${this.orderId}`
		
			this.createSuccessShow = true
		},
		async getList(pageNo = 1) {
			this.loading = true;
			let params = {pageNo: pageNo, pageSize: 10, searchContent: {}}
			if (this.type) {
				params['searchContent']['type'] = this.type
			}
			if (this.finish) {
				params['searchContent']['finish'] = this.finish
			}
			if (this.search) {
				params['searchContent']['search'] = this.search
			}
			if (this.start) {
				params['searchContent']['start'] = this.start
			}
			if (this.end) {
				params['searchContent']['end'] = this.end
			}
			const result = await List_Api(params)

			this.loading = false;
			this.data = result.data.map(item=>{
				return {
					...item, 
					orderType: item.type == '0' ? '上衣' : item.type == '1' ? '裙裤' :'返修', 
					isFinish: item.finish == '0' ? '未完成' : '完成', 
					createTime: this.$moment(item.create_time).format('YYYY-MM-DD HH:mm:ss'),
					updateTime: this.$moment(item.update_time).format('YYYY-MM-DD HH:mm:ss'),
				}
			})
			const pagination = { ...this.pagination };
			pagination.total = result.total;
			this.pagination = pagination
		},

		handleOk(){
			this.$cF.downloadIamge(this.qrcodeUrl, this.orderId)
		},
    showDrawer() {
			this.editListShow = false,
			this.editUser = false;
			this.visible = true;
      this.$nextTick(()=>{
        this.form.setFieldsValue({
					orderName: '',
					numbers: '',
					orderType: '0',
					customerName: '',
					contactName: '',
					phone: '',
					address: ''
        })
      })
    },
    onClose() {
      this.visible = false;
    },
		// 日期选择
		onChange(value, mode) {
			if (value.length == 0) {
				this.start = ''
				this.end = ''
			}else{
				this.start = this.$moment(mode[0]).format('x')
				this.end = this.$moment(mode[1]).format('x')
			}
			this.pagination.current = 1
			this.getList()
		},
		handleTypeChange(value) {
			this.type = value
			this.pagination.current = 1
			this.getList()
		},
		handleChange(value) {
			this.finish = value
			this.pagination.current = 1
			this.getList()
		},
		onSearch(value) {
			this.search = value
			this.pagination.current = 1
			this.getList()
		},
		handleSubmit(e) {
			e.preventDefault();
			this.form.validateFields( async (err, values) => {
				if (!err) {
					this.$message.loading({ content: 'Loading...', key });
					if (this.editListShow) {
						await EditList_Api({
							id: this.currentId,
							name: values.orderName,
							numbers: values.numbers,
							type: values.orderType,
							customerName: values.customerName,
							contactName: values.contactName,
							phone: values.phone,
							address: values.address
						})
						this.$message.success({ content: '修改成功！', key, duration: 2 });
						this.getList(this.pagination.current)
						this.onClose()
					}else{
						console.log(values)
						const result = await AddList_Api({
							name: values.orderName,
							numbers: values.numbers,
							type: values.orderType,
							customerName: values.customerName,
							contactName: values.contactName,
							phone: values.phone,
							address: values.address
						})
						this.$message.success({ content: '开单成功！', key, duration: 2 });
						this.visible = false
						this.orderId = result
						this.createSuccessShow = true
						this.qrcodeUrl = `${this.urlPrefix}${result}`
						this.pagination.current = 1
						this.getList()
					}
				}
			})


		}
	}
};
</script>
<style scoped lang="scss">
.staff{
	overflow: auto;
	.opList{
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
		.typeSelect{
			width: 140px;
		}
		.finishSelect{
			margin: 0px 10px;
			width: 120px;
		}
		.search{
			margin-left: 10px;
			width: 300px;
		}
		
	}


}
.orderImage{
	display: block;
	width: 50%;
	margin: 0 auto;
}
.qrcode{
	width: 80px;
	cursor: pointer;
}
/deep/.ant-table-expanded-row{
	height: 705px;
}
/deep/.ant-modal-header{
	background-color: #1890ff;
	/deep/.ant-modal-title{
		color:#fff;
	}
}
.processList{
	.single{
		display: flex;
		margin-bottom: 15px;
		.step{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 24px;
			height: 24px;
			border: 1.5px solid #47a7ff;
			border-radius: 50%;
			color: #47a7ff;
		}
		.out{
			border: 1px solid #a7a7a7;
			color: #a7a7a7;
		}
		.right{
			margin-left: 20px;
			.name{
				color:#333;
			}
			p{
				margin-bottom: 0;
			}
			.sub{
				color:#8c8c8c;
			}
		}
	}

}
	.finish{
		color: #47a7ff !important;
	}
</style>
