// http.js
import axios from 'axios'
import { message } from 'ant-design-vue'
// 环境的切换
if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'https://zcyxfs.com:7001/'
  // axios.defaults.baseURL = 'http://127.0.0.1:7001/'

} else if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = '/api/'
}


// 请求拦截器
axios.interceptors.request.use(
  config => {
    const storeToken = sessionStorage.getItem('token')
    if (storeToken) {
      const token = `Bearer ${storeToken}`
      config.headers['Authorization'] = token
    }
    return config
  },
  error => {
    return Promise.error(error)
  })

axios.defaults.timeout = 10000

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

// 响应拦截器
axios.interceptors.response.use(response => {
  console.log(response)
  if (response.headers['content-type'] === 'image/png') {
    return Promise.resolve(response.data)
  }else{
    if (response.status && response.status === 200) {
      if (response.data.code === 200) {
        return Promise.resolve(response.data)
      } else {
        message.error(response.data.msg)
        return Promise.reject(response.data.msg)
      }
    } else {
      return Promise.reject(response)
    }
  }
}, error => {
  // 我们可以在这里对异常状态作统一处理
  if (error.response.status) {
    // 处理请求失败的情况
    // 对不同返回码对相应处理
    return Promise.reject(error.response)
  }
})

// get 请求
export function httpGet({ url, params = {}, isPhoto=false}) {
  return new Promise((resolve, reject) => {
    axios.get(url, { params})
    .then((res) => {
      isPhoto ? resolve(res) : resolve(res.data)
    }).catch(err => {
      reject(err)
    })
  })
}

// post请求
export function httpPost({ url, data = {}, params = {}}) {
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: 'post',
      transformRequest: [function (data) {
        let ret = ''
        for (let it in data) {
          ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
        }
        return ret
      }],
      // 发送的数据
      data,
      // url参数
      params

    }).then(res => {
      resolve(res.data)
    }).catch(err =>{
      reject(err)
    })
  })
}