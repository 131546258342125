import Vue from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import router from './router'
import store from './store'

import moment from 'moment'
Vue.prototype.$moment = moment;

// md5
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

// antd
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);

// 员工管理组件
import Staff from '../src/components/staff/Staff'
Vue.component('Staff', Staff)

// 开单、订单管理组件
import ProcessList from '../src/components/processList/ProcessList'
Vue.component('ProcessList', ProcessList)

// 公共方法
import cF from './common/common'
Vue.prototype.$cF = cF;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
